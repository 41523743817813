export default Object.freeze({
  HOME: "/",
  REVENUE: "/revenue",
  LOGIN: "/login",
  LOGIN_STAFF: "/login_staff",
  SIGN_UP: "/sign_up",
  PHONE_VERIFICATION: "/phone_verification",
  EMAIL_VERIFICATION: "/email_verification",
  FORGOT_PASSWORD: "/forgot_password",
  FORGOT_PASSWORD_STAFF: "/forgot_password_staff",
  RESET_PASSWORD: "/reset_password",
  RESET_PASSWORD_STAFF: "/reset_password_staff",
  MERCHANT_DETAIL: "/merchant_detail",
  PROFILE_DETAIL: "/profile_detail",
  RENEWAL_DAY: "/renewal_day",
  BANK_DETAIL: "/bank_detail",
  STAFF_LIST: "/staff/list",
  STAFF_EDIT: "/staff/list/edit",
  STAFF_BUSINESS_ACCESS: "/staff/access",
  STAFF_BUSINESS_ACCESS_EDIT: "/staff/access/edit",

  USER_BUSINESS_ACCESS: "/user/access",
  USER_BUSINESS_ACCESS_EDIT: "/user/access/edit",

  MOBILE_ACCOUNT_MENU: "/account_menu",

  BUSINESS_CREATE: "/business/create",
  BUSINESS_LIST: "/business/list",
  BUSINESS_EDIT: "/business/edit",

  PACKAGE_CREATE: "/package/plan/create",
  PACKAGE_LIST: "/package/plan/list",
  PACKAGE_EDIT: "/package/plan/edit",
  PACKAGE_REPORT: "/package/plan/report",

  APPOINTMENT_LIST: "/appointment/list",
  APPOINTMENT_CREATE: "/appointment/create",

  SCHEDULE_LIST: "/schedule/list",
  SCHEDULE_CREATE: "/schedule/create",
  SCHEDULE_EDIT: "/schedule/edit",

  CALENDAR: "/calendar",
  OFF_DAY_CALENDAR: "/off-day-calendar",

  ADDITIONAL_FEES_LIST: "/additional_fees/list",
  ADDITIONAL_FEES_CREATE: "/additional_fees/create",
  ADDITIONAL_FEES_EDIT: "/additional_fees/edit",

  PROMO_CODES_LIST: "/promo_codes/list",
  PROMO_CODES_CREATE: "/promo_codes/create",
  PROMO_CODES_EDIT: "/promo_codes/edit",

  SUBSCRIPTION_CREATE: "/subscription/plan/create",
  SUBSCRIPTION_LIST: "/subscription/plan/list",
  SUBSCRIPTION_EDIT: "/subscription/plan/edit",
  SUBSCRIBER_LIST: "/subscription/subscriber/list",

  FITNESS_CLASS_CREATE: "/fitness_class/create",
  FITNESS_CLASS_LIST: "/fitness_class/list",
  FITNESS_CLASS_SESSIONS: "/fitness_class/sessions",
  FITNESS_CLASS_SESSIONS_EDIT: "/fitness_class/sessions/edit",
  FITNESS_CLASS_BOOKING: "/fitness_class/booking",
  FITNESS_CLASS_BOOKING_CREATE: "/fitness_class/booking/create",

  EVENT_CREATE: "/event/create",
  EVENT_LIST: "/event/list",
  EVENT_SESSIONS: "/event/sessions",
  EVENT_SESSIONS_EDIT: "/event/sessions/edit",
  EVENT_BOOKING: "/event/booking",
  EVENT_RESPONSES: "/event/responses",

  STATEMENT_CURRENT: "/statement/current",
  STATEMENT_CLOSED: "/statement/closed",
  STATEMENT_DETAIL: "/statement/detail",
  HOURLY_TIMESLOT_LIST: "/hourly_timeslot/list",
  HOURLY_TIMESLOT_EDIT: "/hourly_timeslot/edit",

  EXCLUSIVE_REFERRAL_LIST: "/exclusive_referral/list",

  OFF_HOUR_LIST: "/off_hour/list",
});
