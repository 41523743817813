import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, FormHelperText, IconButton, styled, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
}));

export default function FormDatePicker({
  name,
  isWhiteBg,
  width,
  disabled,
  minDays,
  maxDays,
  showClear,
  setEndDateAsSame,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const currentDate = new Date();

  const handleChange = (date) => {
    setFieldValue(name, date);
    if (setEndDateAsSame) setFieldValue("endDate", date);
  };

  const handleClear = () => {
    setFieldValue(name, null);
  };

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", width }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={handleChange}
          minDate={minDays && new Date()}
          maxDate={maxDays && currentDate.setDate(currentDate.getDate() + maxDays)}
          InputProps={{
            error: showError,
          }}
          renderInput={(params) => (
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <StyledTextField
                sx={{
                  width,
                  backgroundColor: isWhiteBg
                    ? theme.palette.colors.bg.secondary
                    : theme.palette.colors.bg.primary,
                }}
                variant="outlined"
                onBlur={() => setFieldTouched(name)}
                {...params}
              />
              {showClear && values[name] !== null && (
                <IconButton
                  style={{ position: "absolute", top: "1px", margin: "auto", right: "40px" }}
                  onClick={handleClear}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </Box>
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </LocalizationProvider>
    </Box>
  );
}
FormDatePicker.defaultProps = {
  width: null,
  isWhiteBg: false,
  disabled: false,
  minDays: true,
  maxDays: 180,
  showClear: false,
  setEndDateAsSame: false,
};

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isWhiteBg: PropTypes.bool,
  disabled: PropTypes.bool,
  minDays: PropTypes.bool,
  maxDays: PropTypes.number,
  showClear: PropTypes.bool,
  setEndDateAsSame: PropTypes.bool,
};
