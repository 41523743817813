import { Box, FormHelperText, useTheme } from "@mui/material";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

dayjs.extend(customParseFormat);

export default function FormTimePicker({
  name,
  isTransparent,
  width,
  disabled,
  minTime,
  maxTime,
  setEndTimeAnHourLater,
  disablePastTime,
  showQuarterMinute,
  onTimeChange,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const minHour = parseInt(minTime?.split(" ")[1].split(":")[0], 10);
  const minMinute = parseInt(minTime?.split(" ")[1].split(":")[1], 10);
  const maxHour = parseInt(maxTime?.split(" ")[1].split(":")[0], 10);
  const maxMinute = parseInt(maxTime?.split(" ")[1].split(":")[1], 10);
  let hoursDisabled = [0, 1, 2, 3, 24];

  if (minTime || maxTime) {
    hoursDisabled = Array.from({ length: 24 }, (_, index) => index).filter(
      (hour) => hour < minHour || hour > maxHour,
    );
  }

  const onChange = (time) => {
    setFieldValue(name, time);
    if (setEndTimeAnHourLater) setFieldValue("endTime", dayjs(time).add(1, "hour"));
    if (onTimeChange) {
      const tempValues = values;
      tempValues[name] = time;
      onTimeChange(tempValues);
    }
  };

  const disabledDateTime = () => ({
    disabledHours: () => (!disablePastTime ? [] : hoursDisabled),
    disabledMinutes: (hour) => {
      if (!disablePastTime) {
        return [];
      }
      if (hour === minHour || hour === maxHour) {
        // If the current hour is the minimum or maximum hour, consider minMinute and maxMinute
        return Array.from({ length: 60 }, (_, index) => index).filter(
          (minute) =>
            (hour === minHour && minute < minMinute) || (hour === maxHour && minute > maxMinute),
        );
      }
      if (hour === 23) {
        // For hour 23, apply the existing condition
        return Array.from({ length: 29 }, (_, index) => index + 31);
      }
      return [];
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width }}>
      <TimePicker
        name={name}
        value={values[name]}
        onChange={onChange}
        minuteStep={showQuarterMinute ? 15 : 1}
        use12Hours
        disabled={disabled}
        format="h:mm a"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        // eslint-disable-next-line react/jsx-no-bind
        disabledTime={disabledDateTime}
        style={{
          backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
          padding: "10px",
          borderRadius: theme.shape.borderRadius[0],
        }}
      />
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </Box>
  );
}
FormTimePicker.defaultProps = {
  width: null,
  isTransparent: false,
  disabled: false,
  minTime: null,
  maxTime: null,
  setEndTimeAnHourLater: false,
  disablePastTime: true,
  showQuarterMinute: false,
  onTimeChange: null,
};

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  setEndTimeAnHourLater: PropTypes.bool,
  disablePastTime: PropTypes.bool,
  showQuarterMinute: PropTypes.bool,
  onTimeChange: PropTypes.func,
};
