import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Text from "../../../components/text.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function OffHourTableRow({ offHour, onHandleDelete, onHandleUpdate }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onToggleDelete = () => {
    onHandleDelete(offHour);
    handleCloseMenu();
  };

  const onHandleEdit = () => {
    onHandleUpdate(offHour);
    handleCloseMenu();
  };

  return (
    <TableRow
      key={offHour.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
        "& .MuiTypography-body2": {
          color: offHour.timeOverlaps ? "red" : undefined,
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {offHour.business.name}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {moment(offHour.startTime, "HH:mm:ss").format("h:mm A")}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {moment(offHour.endTime, "HH:mm:ss").format("h:mm A")}
        </Text>
      </TableCell>

      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={handleOpenMenu}>
            <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
          </IconButton>
          <StyledMenu
            sx={{ mt: "20px" }}
            id="menu-appbar"
            anchorEl={showMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(showMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem key={1} onClick={onHandleEdit}>
              <Text textAlign="center">Edit</Text>
            </MenuItem>

            <MenuItem key={2} onClick={onToggleDelete}>
              <Text textAlign="center">Remove</Text>
            </MenuItem>
          </StyledMenu>
        </Box>
      </TableCell>
    </TableRow>
  );
}

OffHourTableRow.propTypes = {
  onHandleUpdate: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  offHour: PropTypes.shape({
    id: PropTypes.number,
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    timeOverlaps: PropTypes.bool,
  }).isRequired,
};
