import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import EmailVerificationScreen from "../features/auth/screens/email-verification.screen";
import ForgotPasswordScreen from "../features/auth/screens/forgot-password.screen";
import LoginScreen from "../features/auth/screens/login.screen";
import MerchantDetailScreen from "../features/auth/screens/merchant-detail.screen";
import PhoneVerificationScreen from "../features/auth/screens/phone-verification.screen";
import ResetPasswordScreen from "../features/auth/screens/reset-password.screen";
import SignUpScreen from "../features/auth/screens/sign-up.screen";

import BusinessCreateScreen from "../features/business/screens/business-create.screen";
import BusinessEditScreen from "../features/business/screens/business-edit.screen";
import BusinessListScreen from "../features/business/screens/business-list.screen";
import CalendarScreen from "../features/calendar/screens/calendar.screen";
import PackageCreateScreen from "../features/package/screens/package-create.screen";
import PackageEditScreen from "../features/package/screens/package-edit.screen";
import PackageListScreen from "../features/package/screens/package-list.screen";
import PackageReportScreen from "../features/package/screens/package-report.screen";
import BankDetailScreen from "../features/profile/screens/bank-detail.screen";
import MobileAccountMenu from "../features/profile/screens/mobile-account-menu.screen";
import ProfileDetailScreen from "../features/profile/screens/profile-detail.screen";
import StaffEditScreen from "../features/profile/screens/staff-edit.screen";
import StaffListScreen from "../features/profile/screens/staff-list.screen";
import AppLayout from "../infrastructure/layout/app.layout";
import PrivateRoute from "./private.routes";
import RestrictedRoute from "./restricted.routes";
import SubscriptionListScreen from "../features/subscription/screens/subscription-list.screen";
import SubscriptionCreateScreen from "../features/subscription/screens/subscription-create.screen";
import SubscriptionEditScreen from "../features/subscription/screens/subscription-edit.screen";
import SubscriberListScreen from "../features/subscription/screens/subscriber-list.screen";
import PackagePurchasesListScreen from "../features/package/screens/package-purchases-list.screen";
// import FitnessClassSessionScreen from "../features/fitness/screens/fitness-class-sessions.screen";
import FitnessClassCreateScreen from "../features/fitness/screens/fitness-class-create.screen";
import FitnessClassListScreen from "../features/fitness/screens/fitness-class-list.screen";
import StatementCurrentListScreen from "../features/statement/screens/statement-current-list.screen";
import StatementClosedListScreen from "../features/statement/screens/statement-closed-list.screen";
import StatementDetailScreen from "../features/statement/screens/statement-detail.screen";
import HourlyTimeslotListScreen from "../features/hourly-timeslot/screens/hourly-timeslot-list.screen";
import HourlyTimeslotEditScreen from "../features/hourly-timeslot/screens/hourly-timeslot-edit.screen";
import HourlyBookingListScreen from "../features/hourly-booking/screens/hourly-booking-list.screen";
import RevenueSummaryScreen from "../features/revenue/screens/revenue-summary.screen";
import StaffBusinessAccessScreen from "../features/profile/screens/staff-business-access.screen";
import StaffBusinessAccessEditScreen from "../features/profile/screens/staff-business-access-edit.screen";
import PromoCodeListScreen from "../features/promo-code/screens/promo-code-list.screen";
import PromoCodeCreateScreen from "../features/promo-code/screens/promo-code-create.screen";
import PromoCodeEditScreen from "../features/promo-code/screens/promo-code-edit.screen";
import LoginStaffScreen from "../features/auth/screens/login-staff.screen";
import ForgotPasswordStaffScreen from "../features/auth/screens/forgot-password-staff.screen";
import { getUserPermission } from "../infrastructure/utils";
import ResetPasswordStaffScreen from "../features/auth/screens/reset-password-staff.screen";
import AdditionalFeesListScreen from "../features/additional-fees/screens/additional-fees-list.screen";
import AdditionalFeesCreateScreen from "../features/additional-fees/screens/additional-fees-create.screen";
import AdditionalFeesEditScreen from "../features/additional-fees/screens/additional-fees-edit.screen";
import RenewalDayScreen from "../features/renewal-day/screens/renewal-day.screen";
import UserListScreen from "../features/user/screens/user-list.screen";
import UserHourlyBookingDetailScreen from "../features/user/screens/user-hourly-booking-detail.screen";
import UserClassesDetailScreen from "../features/user/screens/user-classes-detail.screen";
import UserSubscriptionsDetailScreen from "../features/user/screens/user-subscriptions-detail.screen";
import UserPackagesDetailScreen from "../features/user/screens/user-packages-detail.screen";
import FitnessClassSessionScreen from "../features/fitness/screens/fitness-class-sessions.screen";
import FitnessClassSessionEditScreen from "../features/fitness/screens/fitness-class-sessions-edit.screen";
import BookingListScreen from "../features/booking/screens/booking-list.screen";
import BookingCreateScreen from "../features/booking/screens/booking-create.screen";
import EventListScreen from "../features/event/screens/event-list.screen";
import EventCreateScreen from "../features/event/screens/event-create.screen";
import EventSessionScreen from "../features/event/screens/event-sessions.screen";
import EventEditScreen from "../features/event/screens/event-edit.screen";
import EventSessionEditScreen from "../features/event/screens/event-sessions-edit.screen";
import EventBookingScreen from "../features/event/screens/event-booking.screen";
import HomeScreen from "../features/home/screens/home.screen";
import UserEventsDetailScreen from "../features/user/screens/user-events-detail.screen";
import OffDayCalendarScreen from "../features/off-day-calendar/screens/off-day-calendar.screen";
import EventResponsesScreen from "../features/event/screens/event-responses.screen";
import AppointmentListScreen from "../features/appointment/screens/appointment-list.screen";
import AppointmentCreateScreen from "../features/appointment/screens/appointment-create.screen";
import ScheduleListScreen from "../features/schedule/screens/schedule-list.screen";
import ScheduleCreateScreen from "../features/schedule/screens/schedule-create.screen";
import ScheduleEditScreen from "../features/schedule/screens/schedule-edit.screen";
import ExclusiveReferralListScreen from "../features/exclusive-referral/screens/exclusive-referral-list.screen";
import UserBusinessAccessScreen from "../features/user/screens/user-business-acess.screen";
import UserBusinessAccessEditScreen from "../features/user/screens/user-business-access-edit.screen";
import OffHourListScreen from "../features/off-hour/screens/off-hour-list.screen";

function index() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));

  const redirectHandle = () => {
    switch (getUserPermission()[0]) {
      case "ms-finance-read":
        return <RevenueSummaryScreen />;
      case "ms-finance-write":
        return <RevenueSummaryScreen />;
      case "ms-general-read":
        return <ProfileDetailScreen />;
      case "ms-general-write":
        return <ProfileDetailScreen />;
      case "ms-staff-read":
        return <StaffListScreen />;
      case "ms-staff-write":
        return <StaffListScreen />;
      case "ms-operation-read":
        return <HomeScreen />;
      case "ms-operation-write":
        return <HomeScreen />;
      case "ms-trainer-read":
        return <CalendarScreen />;
      case "ms-trainer-write":
        return <CalendarScreen />;
      default:
        return <HomeScreen />;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        <RestrictedRoute exact path="/login" component={() => <LoginScreen />} />
        <RestrictedRoute exact path="/login_staff" component={() => <LoginStaffScreen />} />
        <RestrictedRoute exact path="/sign_up" component={() => <SignUpScreen />} />
        <RestrictedRoute exact path="/forgot_password" component={() => <ForgotPasswordScreen />} />
        <RestrictedRoute
          exact
          path="/forgot_password_staff"
          component={() => <ForgotPasswordStaffScreen />}
        />
        <RestrictedRoute exact path="/reset_password" component={() => <ResetPasswordScreen />} />
        <RestrictedRoute
          exact
          path="/reset_password_staff"
          component={() => <ResetPasswordStaffScreen />}
        />
        <PrivateRoute
          exact
          path="/phone_verification"
          component={() => <PhoneVerificationScreen />}
        />
        <PrivateRoute
          exact
          path="/email_verification"
          component={() => <EmailVerificationScreen />}
        />
        <PrivateRoute exact path="/merchant_detail" component={() => <MerchantDetailScreen />} />
        <AppLayout>
          <PrivateRoute exact path="/" component={() => redirectHandle()} />
          <PrivateRoute exact path="/revenue" component={() => <RevenueSummaryScreen />} />

          <PrivateRoute
            exact
            path="/account_menu"
            component={() => (isMobile ? <MobileAccountMenu /> : <ProfileDetailScreen />)}
          />
          <PrivateRoute exact path="/profile_detail" component={() => <ProfileDetailScreen />} />
          <PrivateRoute exact path="/bank_detail" component={() => <BankDetailScreen />} />
          <PrivateRoute exact path="/staff/list" component={() => <StaffListScreen />} />
          <PrivateRoute exact path="/staff/list/edit" component={() => <StaffEditScreen />} />
          <PrivateRoute
            exact
            path="/staff/access"
            component={() => <StaffBusinessAccessScreen />}
          />
          <PrivateRoute
            exact
            path="/staff/access/edit"
            component={() => <StaffBusinessAccessEditScreen />}
          />

          <PrivateRoute exact path="/business/create" component={() => <BusinessCreateScreen />} />
          <PrivateRoute exact path="/business/list" component={() => <BusinessListScreen />} />
          <PrivateRoute exact path="/business/edit" component={() => <BusinessEditScreen />} />

          <PrivateRoute
            exact
            path="/package/plan/create"
            component={() => <PackageCreateScreen />}
          />
          <PrivateRoute exact path="/package/plan/list" component={() => <PackageListScreen />} />
          <PrivateRoute
            exact
            path="/package/list"
            component={() => <PackagePurchasesListScreen />}
          />

          <PrivateRoute exact path="/package/plan/edit" component={() => <PackageEditScreen />} />
          <PrivateRoute
            exact
            path="/package/plan/report"
            component={() => <PackageReportScreen />}
          />

          <PrivateRoute exact path="/calendar" component={() => <CalendarScreen />} />

          <PrivateRoute exact path="/off-day-calendar" component={() => <OffDayCalendarScreen />} />

          <PrivateRoute exact path="/off-hour/list" component={() => <OffHourListScreen />} />

          <PrivateRoute
            exact
            path="/subscription/plan/list"
            component={() => <SubscriptionListScreen />}
          />
          <PrivateRoute
            exact
            path="/subscription/plan/create"
            component={() => <SubscriptionCreateScreen />}
          />
          <PrivateRoute
            exact
            path="/subscription/plan/edit"
            component={() => <SubscriptionEditScreen />}
          />
          <PrivateRoute
            exact
            path="/subscription/subscriber/list"
            component={() => <SubscriberListScreen />}
          />

          <PrivateRoute
            exact
            path="/fitness_class/create"
            component={() => <FitnessClassCreateScreen />}
          />
          <PrivateRoute
            exact
            path="/fitness_class/list"
            component={() => <FitnessClassListScreen />}
          />
          <PrivateRoute
            exact
            path="/fitness_class/sessions"
            component={() => <FitnessClassSessionScreen />}
          />
          <PrivateRoute
            exact
            path="/fitness_class/sessions/edit"
            component={() => <FitnessClassSessionEditScreen />}
          />
          <PrivateRoute
            exact
            path="/fitness_class/booking"
            component={() => <BookingListScreen />}
          />
          <PrivateRoute
            exact
            path="/fitness_class/booking/create"
            component={() => <BookingCreateScreen />}
          />

          <PrivateRoute exact path="/event/list" component={() => <EventListScreen />} />
          <PrivateRoute exact path="/event/create" component={() => <EventCreateScreen />} />
          <PrivateRoute exact path="/event/edit" component={() => <EventEditScreen />} />
          <PrivateRoute exact path="/event/sessions" component={() => <EventSessionScreen />} />
          <PrivateRoute
            exact
            path="/event/sessions/edit"
            component={() => <EventSessionEditScreen />}
          />
          <PrivateRoute exact path="/event/booking" component={() => <EventBookingScreen />} />
          <PrivateRoute exact path="/event/responses" component={() => <EventResponsesScreen />} />

          <PrivateRoute
            exact
            path="/statement/current"
            component={() => <StatementCurrentListScreen />}
          />
          <PrivateRoute
            exact
            path="/statement/closed"
            component={() => <StatementClosedListScreen />}
          />
          <PrivateRoute
            exact
            path="/statement/detail"
            component={() => <StatementDetailScreen />}
          />
          <PrivateRoute
            exact
            path="/hourly_timeslot/list"
            component={() => <HourlyTimeslotListScreen />}
          />
          <PrivateRoute
            exact
            path="/hourly_timeslot/edit"
            component={() => <HourlyTimeslotEditScreen />}
          />
          <PrivateRoute
            exact
            path="/hourly_booking/list"
            component={() => <HourlyBookingListScreen />}
          />
          <PrivateRoute exact path="/promo_codes/list" component={() => <PromoCodeListScreen />} />
          <PrivateRoute
            exact
            path="/promo_codes/create"
            component={() => <PromoCodeCreateScreen />}
          />
          <PrivateRoute exact path="/promo_codes/edit" component={() => <PromoCodeEditScreen />} />
          <PrivateRoute
            exact
            path="/additional_fees/list"
            component={() => <AdditionalFeesListScreen />}
          />
          <PrivateRoute
            exact
            path="/additional_fees/create"
            component={() => <AdditionalFeesCreateScreen />}
          />
          <PrivateRoute
            exact
            path="/additional_fees/edit"
            component={() => <AdditionalFeesEditScreen />}
          />
          <PrivateRoute exact path="/user/list" component={() => <UserListScreen />} />
          <PrivateRoute exact path="/user/access" component={() => <UserBusinessAccessScreen />} />
          <PrivateRoute
            exact
            path="/user/access/edit"
            component={() => <UserBusinessAccessEditScreen />}
          />
          <PrivateRoute
            exact
            path="/user/hourly_booking_details"
            component={() => <UserHourlyBookingDetailScreen />}
          />
          <PrivateRoute
            exact
            path="/user/fitness_class_details"
            component={() => <UserClassesDetailScreen />}
          />
          <PrivateRoute
            exact
            path="/user/event_details"
            component={() => <UserEventsDetailScreen />}
          />
          <PrivateRoute
            exact
            path="/user/subscription_details"
            component={() => <UserSubscriptionsDetailScreen />}
          />
          <PrivateRoute
            exact
            path="/user/package_details"
            component={() => <UserPackagesDetailScreen />}
          />
          <PrivateRoute exact path="/renewal_day" component={() => <RenewalDayScreen />} />
          <PrivateRoute
            exact
            path="/appointment/list"
            component={() => <AppointmentListScreen />}
          />
          <PrivateRoute
            exact
            path="/appointment/create"
            component={() => <AppointmentCreateScreen />}
          />
          <PrivateRoute exact path="/schedule/list" component={() => <ScheduleListScreen />} />
          <PrivateRoute exact path="/schedule/create" component={() => <ScheduleCreateScreen />} />
          <PrivateRoute exact path="/schedule/edit" component={() => <ScheduleEditScreen />} />
          <PrivateRoute
            exact
            path="/exclusive_referral/list"
            component={() => <ExclusiveReferralListScreen />}
          />
        </AppLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default index;
