import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getOffHours = createAsyncThunk("merchants/businesses/off_hours", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_hours`,
  );
  return response;
});

export const checkOverlapSession = createAsyncThunk(
  "merchants/businesses/off_hours/check_overlap_session",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_hours/check_overlap_sessions`,
      payload,
    );
    return response;
  },
);

export const createOffHour = createAsyncThunk(
  "merchants/businesses/off_hours/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_hours`,
      payload,
    );
    return response;
  },
);

export const updateOffHour = createAsyncThunk(
  "merchants/businesses/off_hours/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_hours/${payload.id}`,
      payload,
    );
    return response;
  },
);
export const deleteOffHour = createAsyncThunk(
  "merchants/businesses/off_hours/delete",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/off_hours/${payload.id}`,
    );
    return response;
  },
);

const offHourSlice = createSlice({
  name: "offHour",
  initialState: {
    getOffHoursObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkOverlapSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createOffHourObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateOffHourObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteOffHourObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetCheckOverlap: (state) => {
      state.checkOverlapSessionObj.status = "idle";
      state.checkOverlapSessionObj.data = null;
    },
  },
  extraReducers: {
    [getOffHours.pending]: (state) => {
      state.getOffHoursObj.status = "pending";
    },
    [getOffHours.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getOffHoursObj.status = "succeeded";
      state.getOffHoursObj.data = data;
      state.getOffHoursObj.successMessage = message;
    },
    [getOffHours.rejected]: (state, action) => {
      const { message } = action.error;

      state.getOffHoursObj.status = "failed";
      state.getOffHoursObj.errorMessage = message;
    },
    [checkOverlapSession.pending]: (state) => {
      state.checkOverlapSessionObj.status = "pending";
    },
    [checkOverlapSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkOverlapSessionObj.status = "succeeded";
      state.checkOverlapSessionObj.data = data;
      state.checkOverlapSessionObj.successMessage = message;
    },
    [checkOverlapSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkOverlapSessionObj.status = "failed";
      state.checkOverlapSessionObj.errorMessage = message;
    },
    [createOffHour.pending]: (state) => {
      state.createOffHourObj.status = "pending";
    },
    [createOffHour.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createOffHourObj.status = "succeeded";
      state.createOffHourObj.data = data;
      state.createOffHourObj.successMessage = message;
    },
    [createOffHour.rejected]: (state, action) => {
      const { message } = action.error;

      state.createOffHourObj.status = "failed";
      state.createOffHourObj.errorMessage = message;
    },
    [updateOffHour.pending]: (state) => {
      state.updateOffHourObj.status = "pending";
    },
    [updateOffHour.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateOffHourObj.status = "succeeded";
      state.updateOffHourObj.data = data;
      state.updateOffHourObj.successMessage = message;
    },
    [updateOffHour.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateOffHourObj.status = "failed";
      state.updateOffHourObj.errorMessage = message;
    },
    [deleteOffHour.pending]: (state) => {
      state.deleteOffHourObj.status = "pending";
    },
    [deleteOffHour.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteOffHourObj.status = "succeeded";
      state.deleteOffHourObj.data = data;
      state.deleteOffHourObj.successMessage = message;
    },
    [deleteOffHour.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteOffHourObj.status = "failed";
      state.deleteOffHourObj.errorMessage = message;
    },
  },
});

export default offHourSlice.reducer;

// state
export const offHourSelector = (state) => state.offHour;

export const { resetCheckOverlap } = offHourSlice.actions;
