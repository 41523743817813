import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Grid,
  Modal,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  checkOverlapSession,
  createOffHour,
  deleteOffHour,
  getOffHours,
  offHourSelector,
  resetCheckOverlap,
  updateOffHour,
} from "../../../services/off-hour/off-hour.slice.service";
import BusinessListAutoCompleteSingleSelect from "../../home/components/business-list-autocomplete-single-select.component";
import OffHourTableRow from "../components/off-hour-table-row.component";
import OffHourTableRowLoader from "../loader/off-hour-table-row-loader.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

export default function OffHourListScreen() {
  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddOffHour, setOpenAddOffHour] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOffHour, setSelectedOffHour] = useState(null);
  const {
    getOffHoursObj,
    createOffHourObj,
    updateOffHourObj,
    deleteOffHourObj,
    checkOverlapSessionObj,
  } = useSelector(offHourSelector);

  const columnMapping = {
    Business: "business.name",
    "Start Time": "startTime",
    "End Time": "endTime",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshOffHourList = (newPage) => {
    setSelectedOffHour(null);
    setPage(newPage);
    dispatch(getOffHours({ page: newPage })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    onRefreshOffHourList(newPage);
  };

  useEffect(() => {
    onRefreshOffHourList(page);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getOffHoursObj.data && getOffHoursObj.status === "succeeded") {
      records = { ...getOffHoursObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const onFieldChange = (values) => {
    const startTime = `${dayjs(values.startTime).format("HH:mm")}`;
    const endTime = `${dayjs(values.endTime).format("HH:mm")}`;

    if (startTime >= endTime) {
      return;
    }

    if (startTime === "Invalid Date" || endTime === "Invalid Date") {
      return;
    }

    if (!values.business || !startTime || !endTime) {
      return;
    }

    const payloadParams = {
      businessId: values.business?.id,
      startTime,
      endTime,
    };

    setIsLoading(true);

    dispatch(checkOverlapSession(payloadParams)).then(() => {
      setIsLoading(false);
    });
  };

  const onHandleUpdate = (offHour) => {
    setSelectedOffHour(offHour);

    formRef.current.setFieldValue("business", {
      id: offHour.business.id,
      option: offHour.business.name,
    });
    formRef.current.setFieldValue("startTime", dayjs(offHour.startTime, "HH:mm:00"));
    formRef.current.setFieldValue("endTime", dayjs(offHour.endTime, "HH:mm:00"));

    dispatch(
      checkOverlapSession({
        businessId: offHour.business.id,
        startTime: dayjs(offHour.startTime, "HH:mm:00").format("HH:mm"),
        endTime: dayjs(offHour.endTime, "HH:mm:00").format("HH:mm"),
      }),
    ).then(() => {
      setIsLoading(false);
    });
    setOpenAddOffHour(true);
  };

  const onHandleDelete = (offHour) => {
    setSelectedOffHour(offHour);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteOffHour({ id: selectedOffHour.id })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshOffHourList(1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderTableBody = () => {
    if (getOffHoursObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getOffHoursObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4}>
              <TableEmptyBox>
                <Text type="TableText">No off hours</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <OffHourTableRow
          offHour={item}
          key={item.id}
          onHandleDelete={onHandleDelete}
          onHandleUpdate={onHandleUpdate}
        />
      ));
    }
    return <OffHourTableRowLoader />;
  };

  const validationSchema = Yup.object().shape({
    business: Yup.mixed().required().label("Business"),
    startTime: Yup.string().required().label("Start time"),
    endTime: Yup.string().required().label("End time"),
  });

  const onCreateOrUpdateOffHour = (values, { resetForm }) => {
    const startTime = `${dayjs(values.startTime).format("HH:mm")}`;
    const endTime = `${dayjs(values.endTime).format("HH:mm")}`;

    if (startTime >= endTime) {
      formRef.current.setFieldError("endTime", "End time must be later than start time");
      return;
    }

    const payloadParams = {
      id: selectedOffHour?.id,
      businessId: values.business.id,
      startTime,
      endTime,
    };

    setIsLoading(true);

    let theApi = createOffHour;

    if (selectedOffHour) {
      theApi = updateOffHour;
    }

    dispatch(theApi(payloadParams)).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        setOpenAddOffHour(false);
        onRefreshOffHourList(1);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Box width="100%">
      <Grid
        item
        container
        xs={12}
        rowSpacing={3}
        spacing={3}
        sx={{
          paddingX: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <BackdropLoading isLoading={isLoading} />

        <DeleteConfirmationModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Remove Off Hour"
          label="Are you sure you wish to remove this off hour?"
          isLoading={deleteOffHourObj.status === "pending"}
          onConfirmClicked={onConfirmDelete}
        />

        <Grid container item xs={12}>
          <Grid item xs={9}>
            <Text variant="screenLabel">Off Hour List</Text>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                setOpenAddOffHour(true);
              }}
              variant="contained"
              sx={{
                backgroundColor: theme.palette.colors.brand.primary,
                width: 40,
                height: 40,
                borderRadius: "100%",
                marginLeft: "auto",
                marginRight: "6px",
                display: "flex",
                minWidth: 0,
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Grid>
        </Grid>
        <Formik
          innerRef={formRef}
          initialValues={{
            business: null,
            startTime: "",
            endTime: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onCreateOrUpdateOffHour}
        >
          {({ resetForm, setFieldValue }) => (
            <Modal
              open={openAddOffHour}
              onClose={() => {
                setSelectedOffHour(null);
                resetForm();
                setFieldValue("business", null);
                setFieldValue("startTime", "");
                setFieldValue("endTime", "");
                setOpenAddOffHour(false);
                dispatch(resetCheckOverlap());
              }}
              aria-labelledby="add off hour"
            >
              <ModalBox
                sx={{
                  padding: isMobile ? "20px" : "30px",
                  width: "90%",
                  maxWidth: "400px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {selectedOffHour ? "Edit" : "Add"} Off Hour
                </Typography>
                <Spacer size="m" />
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={1}
                  sx={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <Grid item xs={12}>
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                    <Spacer />
                    <BusinessListAutoCompleteSingleSelect
                      name="business"
                      placeholder="Business"
                      onFieldChange={onFieldChange}
                    />
                    <Spacer size="m" />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                    <Spacer />
                    <FormTimePicker
                      name="startTime"
                      disablePastTime={false}
                      showQuarterMinute
                      onTimeChange={onFieldChange}
                    />
                    <Spacer size="m" />
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                    <Spacer />
                    <FormTimePicker
                      name="endTime"
                      disablePastTime={false}
                      showQuarterMinute
                      onTimeChange={onFieldChange}
                    />
                    <Spacer size="l" />
                    {checkOverlapSessionObj.status === "pending" ? (
                      <SkeletonLoader height="50px" width="100%" />
                    ) : (
                      <>
                        {checkOverlapSessionObj.status === "succeeded" && (
                          <>
                            {(checkOverlapSessionObj.data.hourly_session_overlap ||
                              checkOverlapSessionObj.data.event_session_overlap.length > 0 ||
                              checkOverlapSessionObj.data.class_session_overlap.length > 0) && (
                              <Typography>Selected time overlaps with these session:</Typography>
                            )}
                            <Spacer size="m" />
                            {checkOverlapSessionObj.data.hourly_session_overlap && (
                              <Typography>1. Gym Session</Typography>
                            )}
                            <Spacer size="m" />
                            {checkOverlapSessionObj.data.event_session_overlap.length > 0 && (
                              <>
                                <Typography>Event Session</Typography>
                                {checkOverlapSessionObj.data.event_session_overlap.map(
                                  (event, index) => (
                                    <Typography key={event}>
                                      {index + 1}. {event}
                                    </Typography>
                                  ),
                                )}
                              </>
                            )}
                            <Spacer size="m" />
                            {checkOverlapSessionObj.data.class_session_overlap.length > 0 && (
                              <>
                                <Typography>Class Session</Typography>
                                {checkOverlapSessionObj.data.class_session_overlap.map(
                                  (event, index) => (
                                    <Typography key={event}>
                                      {index + 1}. {event}
                                    </Typography>
                                  ),
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <Spacer size="l" />
                    <FormSubmitButton
                      isLoading={
                        createOffHourObj.status === "pending" ||
                        updateOffHourObj.status === "pending"
                      }
                    >
                      <Text type="WhiteColor">Submit</Text>
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </ModalBox>
            </Modal>
          )}
        </Formik>
        {getOffHoursObj.status === "succeeded" &&
          getOffHoursObj.data.items.some((item) => item.timeOverlaps) && (
            <Grid item xs={12}>
              <Typography color={theme.palette.colors.text.error} fontWeight="bold">
                *Highlighted red are selected time overlaps, please update to avoid entrance issues
              </Typography>
            </Grid>
          )}

        <Grid item xs={12}>
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={["Business", "Start Time", "End Time", "Action"]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getOffHoursObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Box>
  );
}
